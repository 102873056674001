<div class=" w-100 bgHome">
    <div class="container-w80">
        <div class="row m-0 dpy-5 h-100">
            <div class="col-12 p-0">
                <div class="row m-0 h-100">
                    <div class="desktop-center">
                        <div class="row m-0 desktop-box-design bg-white flex-column">
                            <form [formGroup]="registrationForm" (ngSubmit)="onSubmit()" class="col-12 p-0">
                                <div class="col-12 p-0 form-group">
                                    <div class="row m-0 dmy-3 flex-column justify-content-center text-center">
                                        <!-- <h4 routerLink="/{{slug}}" class="m-0 fw-bold cursor-pointer"> -->
                                        <h4 (click)="backtoHome()" class="m-0 fw-bold cursor-pointer">
                                            {{selectedSchool?.name | titlecase}}
                                        </h4>
                                        <p *ngIf="studentTab<=1 || teacherTab<=1 || studentTab==7 || teacherTab==9" class="m-0 pt-1 fw-semibold" style="font-size: 16px; color: #000;">
                                            {{selectedSchool?.title}}
                                        </p>
                                        <p *ngIf="studentTab<=1 || teacherTab<=1 || studentTab==7 || teacherTab==9" class="m-0 pt-2 mmb-2 fw-regular" style="font-size: 14px;">
                                            <span title="International General Certificate of Secondary Education" placement="bottom">{{selectedSchool?.board | uppercase}} </span> {{selectedSchool?.std}}
                                        </p>
                                    </div>
                                    <div class="row m-0 my-2 py-3 px-3 px-md-5 w-100" *ngIf="(studentTab>0 || teacherTab>0) && (studentTab<7 || teacherTab<10)" style="background: #FDFAFF;">
                                        <div class="timeline w-100" *ngIf="currentuser=='student' &&  studentTab>0 && studentTab<7">
                                            <div class="d-flex flex-row justify-content-between align-items-center w-100">
                                                <p class="m-0" (click)="(studentTabDone>1 && studentTabDone<=5)?studentTab=1:''" style="width: 20%;" [class]="studentTab>=1 ? 'active' : ''">
                                                    <img *ngIf="selectedCity?.flag" [src]="selectedCity?.flag" style="height: 10px;"> {{selectedCity.city ? selectedCity.city : 'City'}}
                                                </p>
                                                <p class="m-0 text-center" (click)="(studentTabDone>2 && studentTabDone<=5)?studentTab=2:''" style="width: 30%;" [class]="studentTab>=2 ? 'active' : ''">{{ grades[0] ? 'Grade ' + grades[0] : 'Grade'}}</p>
                                                <p class="m-0 text-center" (click)="(studentTabDone>5 && studentTabDone<=5)?studentTab=5:''" [class]="studentTab>=3 ? 'active' : ''" style="width: 30%;">Info</p>
                                                <p class="m-0 text-right" [class]="studentTab>=5 ? 'active' : ''" style="width: 20%;">
                                                    Submit Application</p>
                                            </div>
                                            <div class="my-2 d-flex flex-row justify-content-between align-items-center w-100">
                                                <span class="" style="width: 15%;">
                                                    <img [src]="studentTab==1?('../../../assets/images/dots-active.svg'):(studentTab>1?'../../../assets/images/dots-complete.svg':'../../../assets/images/dots.svg')"
                                                        class="w-100 " (click)="(studentTabDone>1 && studentTabDone<=5)?studentTab=1:''">
                                                </span>
                                                <span class="w-100">
                                                    <img [src]="studentTab>1?'../../../assets/images/line-active.svg':'../../../assets/images/line.svg'"
                                                        class="w-100">
                                                </span>
                                                <span class="" style="width: 15%;">
                                                    <img [src]="studentTab==1?'../../../assets/images/dots-next.svg':(studentTab>2?'../../../assets/images/dots-complete.svg':(studentTab==2?'../../../assets/images/dots-active.svg':'../../../assets/images/dots.svg'))"
                                                        class="w-100" (click)="(studentTabDone>2 && studentTabDone<=5)?studentTab=2:''">
                                                </span>
                                                <span class="w-100">
                                                    <img [src]="studentTab>2?'../../../assets/images/line-active.svg':'../../../assets/images/line.svg'"
                                                        class="w-100">
                                                </span>
                                                <span class="" style="width: 15%;">
                                                    <img [src]="studentTab==2?'../../../assets/images/dots-next.svg':(studentTab==7?'../../../assets/images/dots-next.svg':(studentTab==4?'../../../assets/images/dots-active.svg':(studentTab>4?'../../../assets/images/dots-complete.svg':'../../../assets/images/dots.svg')))"
                                                        class="w-100" (click)="(studentTabDone>5 && studentTabDone<=5)?studentTab=5:''">
                                                </span>
                                                <span class="w-100">
                                                    <img [src]="studentTab>5?'../../../assets/images/line-active.svg':'../../../assets/images/line.svg'"
                                                        class="w-100">
                                                </span>
                                                <span class="" style="width: 15%;">
                                                    <img [src]="studentTab==5?'../../../assets/images/dots-next.svg':(studentTab==7?'../../../assets/images/dots.svg':(studentTab==6?'../../../assets/images/dots-active.svg':(studentTab>6?'../../../assets/images/dots-complete.svg':'../../../assets/images/dots.svg')))"
                                                        class="w-100">
                                                </span>
                                            </div>

                                            <div class="w-100 d-flex align-items-center">
                                                <p style="width: 20%;" class="text-left" *ngIf="studentTabDone>1 && studentTabDone<=5" (click)="studentTabDone>1?studentTab=1:''"><u>Edit</u></p>
                                                <p style="width: 30%;" class="text-center" *ngIf="studentTabDone>2 && studentTabDone<=5" (click)="studentTabDone>2?studentTab=2:''"><u>Edit</u></p>
                                                <p style="width: 30%;" class="text-center"></p>
                                                <p style="width: 20%;" class="text-right"></p>
                                            </div>

                                        </div>
                                        <div class="timeline w-100" *ngIf="currentuser=='teacher' && teacherTab>0 && teacherTab<9">
                                            <div class="d-flex flex-row justify-content-between align-items-center w-100">
                                                <p class="m-0" (click)="(teacherTabDone>1 && teacherTabDone<=7)?teacherTab=1:''" [class]="teacherTab>=1 ? 'active' : ''">
                                                    <img *ngIf="selectedCity?.flag" [src]="selectedCity?.flag" style="height: 10px;"> {{ selectedCity.city ? selectedCity.city : 'Location' }}
                                                </p>
                                                <p class="m-0" style="padding-left: 14px;" (click)="(teacherTabDone>2 && teacherTabDone<=7)?teacherTab=2:''" [class]="teacherTab>=2 ? 'active' : ''">
                                                    {{ user_type?.value ? (user_type?.value|titlecase) : 'Role' }} & Info
                                                </p>
                                                <p class="m-0" style="padding-left: 10px;" (click)="(teacherTabDone>4 && teacherTabDone<=7)?teacherTab=4:''" [class]="teacherTab>=4 ? 'active' : ''">
                                                    Specialisation & Grade
                                                </p>
                                                <p class="m-0" (click)="(teacherTabDone>6 && teacherTabDone<=7)?teacherTab=6:''" [class]="teacherTab>=6 ? 'active' : ''">
                                                    Experience & Bio
                                                </p>
                                                <p class="m-0 text-center" [class]="teacherTab>=7 ? 'active' : ''">
                                                    Submit
                                                </p>
                                            </div>
                                            <div class="my-2 d-flex flex-row justify-content-between align-items-center w-100">
                                                <span class="" style="width: 20%;">
                                                    <img [src]="teacherTab==1?'../../../assets/images/dots-active.svg':(teacherTab>=2?'../../../assets/images/dots-complete.svg':'../../../assets/images/dots.svg')"
                                                        (click)="(teacherTabDone>1 && teacherTabDone<=7)?teacherTab=1:''" class="w-100">
                                                </span>
                                                <span class="w-100">
                                                    <img [src]="teacherTab>1?'../../../assets/images/line-active.svg':'../../../assets/images/line.svg'"
                                                        height="1px" class="w-100">
                                                </span>
                                                <span class="" style="width: 20%;">
                                                    <img [src]="teacherTab==2?'../../../assets/images/dots-active.svg':(teacherTab==1?'../../../assets/images/dots-next.svg':(teacherTab>2?'../../../assets/images/dots-complete.svg':'../../../assets/images/dots.svg'))"
                                                        (click)="(teacherTabDone>2 && teacherTabDone<=7)?teacherTab=2:''" class="w-100">
                                                </span>
                                                <span class="w-100">
                                                    <img [src]="teacherTab>2?'../../../assets/images/line-active.svg':'../../../assets/images/line.svg'"
                                                        height="1px" class="w-100">
                                                </span>
                                                <span class="" style="width: 20%;">
                                                    <img [src]="teacherTab==4?'../../../assets/images/dots-active.svg':(teacherTab==3?'../../../assets/images/dots-next.svg':(teacherTab>3) ?'../../../assets/images/dots-complete.svg':'../../../assets/images/dots.svg')"
                                                        (click)="(teacherTabDone>4 && teacherTabDone<=7)?teacherTab=4:''" class="w-100">
                                                </span>
                                                <span class="w-100">
                                                    <img [src]="teacherTab>4?'../../../assets/images/line-active.svg':'../../../assets/images/line.svg'"
                                                        height="1px" class="w-100">
                                                </span>
                                                <span class="" style="width: 20%;">
                                                    <img [src]="teacherTab==6?'../../../assets/images/dots-active.svg':(teacherTab==5?'../../../assets/images/dots-next.svg':(teacherTab>5) ?'../../../assets/images/dots-complete.svg':'../../../assets/images/dots.svg')"
                                                        (click)="(teacherTabDone>6 && teacherTabDone<=7)?teacherTab=6:''" class="w-100">
                                                </span>
                                                <span class="w-100">
                                                    <img [src]="teacherTab>6?'../../../assets/images/line-active.svg':'../../../assets/images/line.svg'"
                                                        height="1px" class="w-100">
                                                </span>
                                                <span class="" style="width: 20%;">
                                                    <img [src]="teacherTab==7?'../../../assets/images/dots-active.svg':(teacherTab==6?'../../../assets/images/dots-next.svg':(teacherTab>7) ?'../../../assets/images/dots-complete.svg':'../../../assets/images/dots.svg')"
                                                        class="w-100">
                                                </span>
                                            </div>
                                            <div class="w-100 d-flex align-items-center">

                                                <p style="width: 20%;" class="text-left" *ngIf="teacherTabDone>1 && teacherTabDone<=7" (click)="teacherTabDone>1?teacherTab=1:''"><u>Edit</u></p>
                                                <p style="width: 20%; text-align-last:left;padding-left: 4%;" class="text-center" *ngIf="teacherTabDone>2 && teacherTabDone<=7" (click)="teacherTabDone>2?teacherTab=2:''"><u>Edit</u></p>
                                                <p style="width: 20%; text-align-last:left;padding-left: 8%;" class="text-center" *ngIf="teacherTabDone>4 && teacherTabDone<=7" (click)="teacherTabDone>4?teacherTab=4:''"><u>Edit</u></p>
                                                <p style="width: 20%; text-align-last:left;padding-left: 12%;" class="text-center" *ngIf="teacherTabDone>6 && teacherTabDone<=7" (click)="teacherTabDone>6?teacherTab=6:''"><u>Edit</u></p>
                                                <p style="width: 20%;" class="text-right"></p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mx-md-3 mx-3" *ngIf="studentTab==0">
                                        <div class="col-12 p-0">
                                            <div class="row m-0 mobile-s-tab0 p-3">
                                                <p class="m-0">
                                                    <!-- Join thousands of enthusiastic learners online from 10000+ cities. -->
                                                    Join lac's of enthusiastic learners online from thousands cities.
                                                </p>
                                                <!-- <img class="mt-2" src="../../../assets/images/take-tour.svg" width="100%" style="border-radius: 9px;"> -->
                                                <img class="mt-2" src="../../../assets/images/gvsHome.png" width="100%" style="border-radius: 9px;">
                                            </div>
                                            <div class="row m-0 mt-2">
                                                <button routerLink="/{{slug}}/student" class="btn studentApply">Students Apply </button>
                                                <div class="offset-3 col-6 p-0 separator position-relative">
                                                    <!-- <p class="px-2">or</p> -->
                                                </div>
                                                <button routerLink="/{{slug}}/teacher" class="btn teacherApply">Principals Apply</button>
                                                <!-- <div class="w-100 mt-4" style="background: linear-gradient(135deg, #C99AE9, #9D5BCB);height: 1px;">
                                                </div> -->
                                                <!-- <a class="btn p-1 px-2 about" (click)="studentTab!=6 ? (studentTab=6) : ''">
                                                    About Us
                                                </a> -->
                                            </div>
                                            <div class="row m-0 mt-2">
                                                <a class="btn p-1 px-2 about" (click)="studentTab!=7 ? (studentTab=7) : ''">
                                                    About Us
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="studentTab==1 || teacherTab==1" class="row m-0 mt-4">
                                        <div class="col-12 offset-sm-2 col-sm-8 p-0">
                                            <div class="row m-0 justify-content-center">
                                                <div class="w-100 pr-1 ddownParent">
                                                    <div ngbDropdown class=" select-form-control cursor-pointer ddown">
                                                        <div class="">
                                                            <input type="text" id="selectCode" ngbDropdownToggle class="form-control text-center" maxlength="15" required [placeholder]="currentuser == 'student'?'Which City do you want admission in ?':'Which city are you from?'" (keyup)="searchCity($event)" [value]="selectedCity.city ? (selectedCity.city +', '+ selectedCity.country) : ''"
                                                                autocomplete="off">
                                                        </div>
                                                        <div *ngIf="showCity" ngbDropdownMenu aria-labelledby="selectCode" class="ddmenu px-2">
                                                            <a class="dropdown-item mt-2" *ngFor="let ct of cities" (click)="showCity==true ? (showCity=false):(showCity=true);selectCity(ct.id)">
                                                                {{ct?.city}}, {{ct?.country}}
                                                            </a>
                                                            <div class="w-100 pt-4 text-center" *ngIf="cityErrorMsg && cityErrorMsg.length>0" style="color: rgb(189, 13, 13);font-family: arial;">
                                                                <p>{{cityErrorMsg}}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row form popular-city" style="width: 75%; margin: 20px auto;">
                                                <p class="w-100 m-0 pt-2 mmb-2 fw-regular" style="font-size: 14px;">
                                                    Top Cities:
                                                </p>
                                                <span *ngFor="let ct of topCities; let i = index;" class="w-auto cursor-pointer" [class]="ct.id==selectedCity?.id ? 'active' : ''" (click)="selectCity(ct.id)">{{ct.city|titlecase}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="teacherTab==2" class="row mx-md-5 mx-4">
                                        <div class="col-12 offset-sm-2 col-sm-8 p-0">
                                            <div class="row m-0 userType justify-content-center">
                                                <p class="m-0 py-3 text-center">Applying for the role of :</p>
                                                <!-- <a class="m-0 w-100 py-2 text-center mb-3 br-14 uType" [class]="user_type?.value=='teacher'?'active':''" (click)="setUserType('teacher')">Teacher</a> -->
                                                <a class="m-0 w-100 py-2 text-center mb-3 br-14 uType" [class]="user_type?.value=='principal'?'active':''" (click)="setUserType('principal')">Principal</a>
                                                <p class="m-0 py-3 text-center">What makes you a great principal ?
                                                </p>
                                                <textarea class="w-100 p-3 br-14 font-15" formControlName="desc" placeholder="Tell us about your teaching experience " style="min-height: 145px;"></textarea>
                                                <div class="errorBox" *ngIf="desc?.invalid && (desc?.dirty || desc?.touched)">
                                                    <div *ngIf="desc?.errors?.required">Description required.</div>
                                                    <div *ngIf="desc?.errors?.minlength">Minimum 100 character required.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="teacherTab==4" class="row mx-md-5 mx-4">
                                        <div class="col-12  offset-sm-2 col-sm-8 p-0">
                                            <div class="row m-0 d-flex flex-row">
                                                <p class="m-0 py-3">Which subject do you specialize in ?</p>
                                                <ng-select formControlName="subject" class="selectParent disable-arrow disable-dropdown disable-clear-all w-100" [items]="subjects" bindLabel="name" bindValue="id" placeholder="Subjects" [multiple]="true" [clearable]="false">
                                                </ng-select>
                                                <div class="d-flex my-4 flex-wrap popular-city" style="color: #cbcbcb;">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="studentTab==2 || teacherTab==5" class="row m-0">
                                        <div class="col-12 p-0">
                                            <div *ngIf="currentuser == 'student'" class="row m-0 text-center justify-content-center mobile-only">
                                                <p class="m-0 pt-3 font-15 fw-semibold">Choose your Grade</p>
                                            </div>
                                            <div class="row m-0 mx-md-5 mt-3 px-md-3 fw-semibold">
                                                <div class="col-3 p-0 text-center">
                                                    <p class="py-1" style="font-size: 12px;">Kindergarten</p>
                                                </div>
                                                <div class="col-3 p-0 text-center">
                                                    <p class="py-1" style="font-size: 12px;">Junior School</p>
                                                </div>
                                                <div class="col-3 p-0 text-center">
                                                    <p class="py-1" style="font-size: 12px;">Middle School</p>
                                                </div>
                                                <div class="col-3 p-0 text-center">
                                                    <p class="py-1" style="font-size: 12px;">High School</p>
                                                </div>
                                            </div>
                                            <div class="row m-0 grad-box mx-md-5 px-md-3">
                                                <div class="col-3 p-0">
                                                    <div class="row grade-block" *ngFor="let std of kindergartenStd; let i = index;" [title]="std=='LKG'?'Lower Kindergarten':(std=='UKG'?'Upper Kindergarten':'')">
                                                        <div class="box" [class]="(selectedGrade(std))?'active':''" (click)="setGrade(std)">
                                                            <p style="font-size: 0.7rem;">{{std}}</p>
                                                            <div class="gradeLink">
                                                                <span *ngIf="currentuser=='student'" class="text-center">
                                                                    {{leftSeat(std)}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-3 p-0">
                                                    <div class="row grade-block" *ngFor="let std of juniorStd; let i = index;">
                                                        <div class="box" [class]="(selectedGrade(std))?'active':''" (click)="setGrade(std)">
                                                            <p>{{std}}</p>
                                                            <div class="gradeLink">
                                                                <span *ngIf="currentuser=='student'" class="text-center">
                                                                        {{leftSeat(std)}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-3 p-0">
                                                    <div class="row grade-block" *ngFor="let std of middleStd; let i = index;">
                                                        <div class="box" [class]="(selectedGrade(std))?'active':''" (click)="currentuser=='teacher'?setGrade(std):(std<8?setGrade(std):comingSoon($event))">
                                                            <p class="notcomoing" style="pointer-events: none;">{{std}}</p>
                                                            <div class="notcomoing gradeLink" style="pointer-events: none;">
                                                                <span *ngIf="currentuser=='student'" class="text-center">
                                                                        {{leftSeat(std)}}</span>
                                                                <!-- <p *ngIf="currentuser=='student' && std<8" class="viewMore text-center" (click)="openViewMore(std)">View More</p> -->
                                                            </div>
                                                            <div class="comingSoon">
                                                                <p class="dp-4" style="pointer-events: none;">
                                                                    Coming Soon
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-3 p-0">
                                                    <div class="row grade-block" *ngFor="let std of highStd; let i = index;">
                                                        <div class="box" [class]="(selectedGrade(std))?'active':''" (click)="currentuser=='teacher'?setGrade(std):comingSoon($event)">
                                                            <p class="notcomoing" style="pointer-events: none;">{{std}}</p>
                                                            <div class="notcomoing gradeLink" style="pointer-events: none;">
                                                                <span *ngIf="currentuser=='student'" class="text-center">
                                                                    {{leftSeat(std)}}</span>
                                                            </div>
                                                            <div class="comingSoon">
                                                                <p class="dp-4" style="pointer-events: none;">
                                                                    Coming Soon
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="teacherTab==6" class="row mx-md-5 mx-4">
                                        <div class="col-12 p-0">
                                            <div class="row m-0 userType justify-content-center">
                                                <p class="m-0 py-4 text-center w-100">Previous teaching experience? </p>
                                                <a class="py-2 text-center br-14 experience" style="width: 46%; margin: 0 2% 1rem 2%;" [class]="experience?.value=='Online classes'?'active':''" (click)="setExperience('Online classes')">Online classes</a>
                                                <a class="py-2 text-center br-14 experience" style="width: 46%; margin: 0 2% 1rem 2%;" [class]="experience?.value=='Home school'?'active':''" (click)="setExperience('Home school')">Home school</a>
                                                <a class="py-2 text-center br-14 experience" style="width: 46%; margin: 0 2% 1rem 2%;" [class]="experience?.value=='Reputed Institutes'?'active':''" (click)="setExperience('Reputed Institutes')">Reputed Institutes</a>
                                                <a class="py-2 text-center br-14 experience" style="width: 46%; margin: 0 2% 1rem 2%;" [class]="experience?.value=='None'?'active':''" (click)="setExperience('None')">None</a>
                                            </div>
                                            <div class="row m-0 userType justify-content-center">
                                                <p class="m-0 py-4 text-center w-100">Choose Experience Level</p>
                                                <a class="py-2 text-center br-14 experience_in_year" style="width: 23%; margin: 0 1%;" [class]="experience_in_year?.value=='0-2'?'active':''" (click)="setexperience_in_year('0-2')">0-2</a>
                                                <a class="py-2 text-center br-14 experience_in_year" style="width: 23%; margin: 0 1%;" [class]="experience_in_year?.value=='2-5'?'active':''" (click)="setexperience_in_year('2-5')">2-5</a>
                                                <a class="py-2 text-center br-14 experience_in_year" style="width: 23%; margin: 0 1%;" [class]="experience_in_year?.value=='6-10'?'active':''" (click)="setexperience_in_year('6-10')">6-10</a>
                                                <a class="py-2 text-center br-14 experience_in_year" style="width: 23%; margin: 0 1%;" [class]="experience_in_year?.value=='10+'?'active':''" (click)="setexperience_in_year('10+')">10+</a>
                                            </div>
                                            <div class="row m-0 justify-content-center">
                                                <p class="m-0 py-4 text-center">Add Resume </p>
                                                <input class="d-none" id="resume-upload" type="file" accept="application/pdf,application/msword,
                                    application/vnd.openxmlformats-officedocument.wordprocessingml.document" (change)="fileChange($event)" />
                                                <label for="resume-upload" class="fileUploadLbl blink_me m-0 w-100 py-2 text-center mb-3 br-14 cursor-pointer"><span
                                                        class="upload-icon google-plus-btn-icon"></span>{{rname?rname:'Import'}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="studentTab==3 || teacherTab==3" class="row m-0">
                                        <div *ngIf="currentuser=='student'" class="offset-1 col-10  offset-sm-2 col-sm-8 p-0 studentSlider">
                                            <owl-carousel-o [options]="descSlider">
                                                <ng-template carouselSlide>
                                                    <div class="slider-box">
                                                        <div class="row m-0">
                                                            <div class="offset-3 col-6 p-0 p-4">
                                                                <img src="../../../assets/images/s2.svg" class="w-100">
                                                            </div>
                                                            <div class="col-12 p-0 text p-4 slider-text">
                                                                <h5>
                                                                    Online Learning Content
                                                                </h5>
                                                                <p class="text-center">
                                                                    Access detailed course outlines, multi-sensory video and interactive lessons available 24/7 on mobile, laptop, tablet and PC.
                                                                    <!-- Import CarouselModule into a module which declares a component intended to have a carousel -->
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                                <ng-template carouselSlide>
                                                    <div class="slider-box">
                                                        <div class="row m-0">
                                                            <div class="offset-3 col-6 p-0 p-4">
                                                                <img src="../../../assets/images/s3.svg" class="w-100">
                                                            </div>
                                                            <div class="col-12 p-0 text p-4 slider-text">
                                                                <h5>
                                                                    Direct chat with Teachers
                                                                </h5>
                                                                <p class="text-center m-0">
                                                                    Have a question for your teacher?
                                                                    <br> Ask your teacher immediately via the platform messenger and receive an answer that day.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                                <ng-template carouselSlide>
                                                    <div class="slider-box">
                                                        <div class="row m-0">
                                                            <div class="offset-3 col-6 p-0 p-4">
                                                                <img src="../../../assets/images/s4.svg" class="w-100">
                                                            </div>
                                                            <div class="col-12 p-0 text p-4 slider-text">
                                                                <h5>
                                                                    Live & Recorder Lessons
                                                                </h5>
                                                                <p class="text-center">
                                                                    Interact with your teacher and other students via weekly online classroom sessions giving you the ability to get answers to your questions.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                                <ng-template carouselSlide>
                                                    <div class="slider-box">
                                                        <div class="row m-0">
                                                            <div class="offset-3 col-6 p-0 p-4">
                                                                <img src="../../../assets/images/s5.svg" class="w-100">
                                                            </div>
                                                            <div class="col-12 p-0 text p-4 slider-text">
                                                                <h5>
                                                                    Student Tracker
                                                                </h5>
                                                                <p class="text-center">
                                                                    Easily track improvement across each of your courses and get marked assignments with teachers feedback.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                                <ng-template carouselSlide>
                                                    <div class="slider-box">
                                                        <div class="row m-0">
                                                            <div class="offset-3 col-6 p-0 p-4">
                                                                <img src="../../../assets/images/s6.svg" class="w-100">
                                                            </div>
                                                            <div class="col-12 p-0 text p-4 slider-text">
                                                                <h5>
                                                                    International Accreditation
                                                                </h5>
                                                                <p class="text-center">
                                                                    GVS is accredited with Cambridge, with the shared goal of a holistic education focused on providing every opportunity that knowledge brings.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                                <ng-template carouselSlide>
                                                    <div class="slider-box">
                                                        <div class="row m-0">
                                                            <div class="offset-3 col-6 p-0 p-4">
                                                                <img src="../../../assets/images/s7.svg" class="w-100">
                                                            </div>
                                                            <div class="col-12 p-0 text p-4 slider-text">
                                                                <h5>
                                                                    Marked Assignments & Exams
                                                                </h5>
                                                                <p class="text-center">
                                                                    Submit your assignments and mock exams via the platform and get personalised feedback.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </owl-carousel-o>
                                        </div>
                                        <div *ngIf="currentuser=='teacher' && user_type?.value=='teacher'" class="offset-1 col-10  offset-sm-2 col-sm-8 p-0 teacherSlider">
                                            <owl-carousel-o [options]="descSlider">
                                                <ng-template carouselSlide>
                                                    <div class="slider-box">
                                                        <div class="row m-0">
                                                            <div class="offset-3 col-6 p-0 p-4">
                                                                <img src="../../../assets/images/t2.svg" class="w-100">
                                                            </div>
                                                            <div class="col-12 p-0 text p-4 slider-text">
                                                                <h5>
                                                                    Teachers Job Brief
                                                                </h5>
                                                                <p class="">
                                                                    &#9733; We're looking for a competent Teacher Assistant to help our lead Teachers in educating our students. You'll get to work with children of various ages and educational needs, while learning how to craft effective lesson plans and organize classrooms.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                                <ng-template carouselSlide>
                                                    <div class="slider-box">
                                                        <div class="row m-0">
                                                            <div class="offset-3 col-6 p-0 p-4">
                                                                <img src="../../../assets/images/t3.svg" class="w-100">
                                                            </div>
                                                            <div class="col-12 p-0 text p-4 slider-text">
                                                                <h5>
                                                                    Teachers Job Brief
                                                                </h5>
                                                                <p class="m-0">
                                                                    &#9733; As a Teacher Assistant, You'll be responsible for individual students
                                                                    <!-- </p>
                                                                <p class="m-0"> -->
                                                                    <br> - or smaller groups of students -
                                                                    <br>
                                                                    <!-- </p>
                                                                <p> -->
                                                                    who need to assistance to absorb the lesson material. You’ll work with the lead Teacher to build plans for class and you’ll also help them with record-keeping
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                                <ng-template carouselSlide>
                                                    <div class="slider-box">
                                                        <div class="row m-0">
                                                            <div class="offset-3 col-6 p-0 p-4">
                                                                <img src="../../../assets/images/t4.svg" class="w-100">
                                                            </div>
                                                            <div class="col-12 p-0 text p-4 slider-text">
                                                                <h5>
                                                                    Teachers Job Brief
                                                                </h5>
                                                                <p class="">
                                                                    &#9733; To do this job well, you should be smart and patient with a positive attitude. If you’re ready to develop yourself in the world of teaching and do some real good for our students, we’d like to meet you.
                                                                </p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                                <ng-template carouselSlide>
                                                    <div class="slider-box">
                                                        <div class="row m-0">
                                                            <div class="col-12 p-0 text p-4" style="border-top-left-radius: 18px;border-top-right-radius: 18px;">
                                                                <h5>
                                                                    Teachers Responsibilities
                                                                </h5>
                                                                <p class="m-0">
                                                                    &#9733; Help teachers prepare lesson plans
                                                                </p>

                                                                <p class="m-0">
                                                                    &#9733; Set up necessary material for classes (e.g. projectors, chemistry sets)
                                                                </p>
                                                                <p class="m-0">

                                                                    &#9733; Track student attendance and class schedules
                                                                </p>
                                                                <p class="m-0">

                                                                    &#9733; Review material taught in class with individual students with learning challenges
                                                                </p>
                                                                <p class="m-0">

                                                                    &#9733; Work with smaller groups of students for remedial teaching or reinforcing the learning process
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                                <ng-template carouselSlide>
                                                    <div class="slider-box">
                                                        <div class="row m-0">
                                                            <div class="col-12 p-0 text p-4" style="border-top-left-radius: 18px;border-top-right-radius: 18px;">
                                                                <h5>
                                                                    Teachers Responsibilities
                                                                </h5>
                                                                <p class="m-0">
                                                                    &#9733; Escort and supervise students in field trips and school activities
                                                                </p>
                                                                <p class="m-0">
                                                                    &#9733; Make sure classrooms are safe and tidy before classes start
                                                                </p>
                                                                <p class="m-0">
                                                                    &#9733; Assist teachers with various tasks, like grading assignments and informing parents of their children’s progress
                                                                </p>
                                                                <p class="m-0">
                                                                    &#9733; Help students adjust, learn and socialize and report to teachers about possible behavioral issues
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </owl-carousel-o>
                                        </div>
                                        <div *ngIf="currentuser=='teacher' && user_type?.value=='principal'" class="offset-1 col-10  offset-sm-2 col-sm-8 p-0 principalSlider">
                                            <owl-carousel-o [options]="descSlider">
                                                <ng-template carouselSlide>
                                                    <div class="slider-box">
                                                        <div class="row m-0">
                                                            <div class="offset-3 col-6 p-0 p-4">
                                                                <img src="../../../assets/images/p2.svg" class="w-100">
                                                            </div>
                                                            <div class="col-12 p-0 text p-4 slider-text">
                                                                <h5>
                                                                    Principals Task Brief
                                                                </h5>
                                                                <p class="">
                                                                    We are looking for a capable Leader to partner with us with a minimum 1 yr overall experience and foster a healthy learning environment for the students & teachers in their city.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                                <ng-template carouselSlide>
                                                    <div class="slider-box">
                                                        <div class="row m-0">
                                                            <div class="offset-3 col-6 p-0 p-4">
                                                                <img src="../../../assets/images/p3.svg" class="w-100">
                                                            </div>
                                                            <div class="col-12 p-0 text p-4 slider-text">
                                                                <h5>
                                                                    Principals Task Brief
                                                                </h5>
                                                                <p class="">
                                                                    &#9733; Your primary responsibilities will be to supervise your education and oversee day-to-day online school operations.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                                <ng-template carouselSlide>
                                                    <div class="slider-box">
                                                        <div class="row m-0">
                                                            <div class="offset-3 col-6 p-0 p-4">
                                                                <img src="../../../assets/images/p4.svg" class="w-100">
                                                            </div>
                                                            <div class="col-12 p-0 text p-4 slider-text">
                                                                <h5>
                                                                    Principals Task Brief
                                                                </h5>
                                                                <p class="">
                                                                    &#9733; To succeed in this role, you should be able to make quick decisions under pressure and have a comprehensive understanding of the national and International education system and its regulations. Excellent communication and organizational abilities
                                                                    are also important skills you should demonstrate as a principal.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                                <ng-template carouselSlide>
                                                    <div class="slider-box">
                                                        <div class="row m-0">
                                                            <div class="offset-3 col-6 p-0 p-4">
                                                                <img src="../../../assets/images/p5.svg" class="w-100">
                                                            </div>
                                                            <div class="col-12 p-0 text p-4 slider-text">
                                                                <h5>
                                                                    Principals Task Brief
                                                                </h5>
                                                                <p class="">
                                                                    &#9733; If you meet these requirements and you’re passionate about a leadership role in education, we’d like to meet you.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                                <ng-template carouselSlide>
                                                    <div class="slider-box">
                                                        <div class="row m-0">
                                                            <div class="col-12 p-0 text p-4" style="border-top-left-radius: 18px;border-top-right-radius: 18px;">
                                                                <h5>
                                                                    Principals Responsibilities
                                                                </h5>
                                                                <p class="m-0">
                                                                    &#9733; Oversee day-to-day online school operations
                                                                </p>
                                                                <p class="m-0">
                                                                    &#9733; Manage school logistics and budgets
                                                                </p>
                                                                <p class="m-0">
                                                                    &#9733; Set learning goals for students and teachers based on national and international curricula.
                                                                </p>
                                                                <p class="m-0">
                                                                    &#9733; Monitor and report on teacher performance Reviews and timely Appraisals
                                                                </p>
                                                                <p class="m-0">
                                                                    &#9733; Present data from school performance to board members regularly
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                                <ng-template carouselSlide>
                                                    <div class="slider-box">
                                                        <div class="row m-0">
                                                            <div class="col-12 p-0 text p-4" style="border-top-left-radius: 18px;border-top-right-radius: 18px;">
                                                                <h5>
                                                                    Principals Responsibilities
                                                                </h5>
                                                                <p class="m-0">
                                                                    &#9733; Research new resources and techniques to improve teaching pedagogy
                                                                </p>
                                                                <p class="m-0">
                                                                    &#9733; Interview and hire student counts meeting Targets regularly
                                                                </p>
                                                                <p class="m-0">
                                                                    &#9733; Review and implement school policies
                                                                </p>
                                                                <p class="m-0">
                                                                    &#9733; Provide guidance and counseling to teachers, if required,.. Else forward details to the Management
                                                                </p>
                                                                <p class="m-0">
                                                                    &#9733; Handle emergencies and school crises from time to time
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                                <ng-template carouselSlide>
                                                    <div class="slider-box">
                                                        <div class="row m-0">
                                                            <div class="col-12 p-0 text p-4" style="border-top-left-radius: 18px;border-top-right-radius: 18px;">
                                                                <h5>
                                                                    Principals Responsibilities
                                                                </h5>
                                                                <p class="m-0">
                                                                    &#9733; Organize school online events and assemblies
                                                                </p>
                                                                <p class="m-0">
                                                                    &#9733; Attend conferences to gain knowledge on current educational trends
                                                                </p>
                                                                <p class="m-0">
                                                                    &#9733; Partnership investment will be $1000 complete information for the same will be provided in the interview for which you will be invited once you apply..
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </owl-carousel-o>
                                        </div>
                                        <div class="col-12 p-0 mt-5">
                                            <div class="row m-0">
                                                <div class="offset-2 col-4 p-0">
                                                    <a (click)="currentuser == 'student'?(studentTab=2 && studentTabDone=2):(teacherTab=2 && teacherTabDone=2)" style="color: #AA6DD4;font-size: 11px;text-decoration: underline;">Back to Grade</a>
                                                </div>
                                                <div class="col-4 p-0">
                                                    <button (click)="currentuser == 'student'?(studentTab=4 && studentTabDone=4):(teacherTab=4 && teacherTabDone=4)" class="btn btn-proceed">Skip to Details</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="studentTab==4" class="row m-0">
                                        <div class="col-12 p-0">
                                            <div *ngIf="currentuser == 'student'" class="row m-0 mx-5 mobile-only-principalDetail">
                                                <div class="col-3" style="padding: 10px;">
                                                    <div class="w-100">
                                                        <p class="m-0 w-100" style="border-radius: 13px; background-color: #fff; aspect-ratio: 1; border: 1px solid #707070;">
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-9 font-10" style="padding: 10px;">
                                                    <p class="m-0 fw-semibold">{{selectedPrincipal?.name}}
                                                    </p>
                                                    <p class="m-0 fw-regular">Principal</p>
                                                    <p class="m-0 fw-regular">{{selectedPrincipal?.email}}</p>
                                                    <p class="m-0 fw-light"><img src="../../../assets/images/Icon metro-location.svg" width="8px" class="mr-2">{{selectedCity?.city}}, {{selectedCity?.country}}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="row m-0">
                                                <div class="col-12 p-0">
                                                    <div class="row p-4 mobile-s-tab3">
                                                        <div class="col-12 p-0">
                                                            <div *ngIf="currentuser=='student'" class="row m-0">
                                                                <div class="col-12 p-0">
                                                                    <div class="row m-0 br-12 p-3" style="border: 1px solid #A8A8A8;">
                                                                        <div class="col-7 p-0">
                                                                            <p class="fw-semibold w-100" style="font-size: 12px;">
                                                                                Live Learning
                                                                            </p>
                                                                            <div class="row m-0">
                                                                                <div class="col-2 p-0">
                                                                                    <img style="border-radius: 7px; aspect-ratio: 1;" src="../../../assets/images/principal-img.png" width="100%">
                                                                                </div>
                                                                                <div class="col-2 p-0 pl-1" style="font-size: 10px;">
                                                                                    <p class="m-0 fw-semibold">
                                                                                        {{selectedPrincipal?.name}}
                                                                                    </p>
                                                                                    <p class="m-0 fw-semibold">Principal</p>
                                                                                    <!-- <p class="m-0 fw-regular">
                                                                                        {{selectedPrincipal?.email}}
                                                                                    </p> -->
                                                                                    <p class="m-0 fw-regular">
                                                                                        {{selectedPrincipal?.qualification | titlecase}}</p>
                                                                                    <!-- <p class="m-0 fw-light"><img src="../../../assets/images/Icon metro-location.svg" width="8px" class="mr-2">{{selectedCity?.city}}, {{selectedCity?.country}}
                                                                                    </p> -->
                                                                                </div>
                                                                            </div>
                                                                            <div class="row m-0 mt-2">
                                                                                <p class="fw-semibold mb-1" style="font-size: 12px;">
                                                                                    {{selectedBoard.board_name}},&nbsp;&nbsp;&nbsp;Grade {{grades[0]}}
                                                                                </p>
                                                                            </div>
                                                                            <div class="row m-0">
                                                                                <p class="fw-semibold mb-1" style="font-size: 12px;">
                                                                                    Teacher: Student Ratio - 1:15
                                                                                </p>
                                                                            </div>
                                                                            <div class="row m-0 mb-1" style="font-size: 12px;">
                                                                                <span class="fw-semibold">
                                                                                    Total Fees &nbsp;&nbsp;&nbsp;&nbsp; 
                                                                                </span>$2000/-
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-5 p-0">
                                                                            <div class="row m-0">
                                                                                <p class="fw-semibold text-center w-100" style="font-size: 12px;">
                                                                                    Subjects
                                                                                </p>
                                                                            </div>
                                                                            <div class="row m-0">
                                                                                <p class="fw-medium m-1 text-center w-100" style="font-size: 12px;">
                                                                                    Science
                                                                                </p>
                                                                            </div>
                                                                            <div class="row m-0">
                                                                                <p class="fw-medium m-1 text-center w-100" style="font-size: 12px;">
                                                                                    English
                                                                                </p>
                                                                            </div>
                                                                            <div class="row m-0">
                                                                                <p class="fw-medium m-1 text-center w-100" style="font-size: 12px;">
                                                                                    Global Citizenship
                                                                                </p>
                                                                            </div>
                                                                            <div class="row m-0">
                                                                                <p class="fw-medium m-1 text-center w-100" style="font-size: 12px;">
                                                                                    Maths
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-12 p-0">
                                                                    <div class="row m-0 mt-3">
                                                                        <p class="fw-semibold w-100 m-0" style="font-size: 12px;">
                                                                            Global Citizenship
                                                                        </p>
                                                                    </div>
                                                                    <div class="row m-0">
                                                                        <p class=" m-0 w-100 viewMoreText fw-medium">
                                                                            A Daily Session on Global Citizenship where students will have a great experience as they study with the learners from different cities worldwide
                                                                        </p>
                                                                    </div>
                                                                    <div class="row m-0 mt-3">
                                                                        <div class="col-3 p-0">
                                                                            <p class="fw-semibold w-100 m-0" style="font-size: 12px;">
                                                                                Required
                                                                            </p>
                                                                        </div>
                                                                        <div class="col-9 p-0 viewMoreText">
                                                                            <!-- A Daily Session on Global Citizenship where students will have a great -->
                                                                            Students must know how to read
                                                                        </div>
                                                                    </div>
                                                                    <div class="row m-0 mt-3">
                                                                        <div class="col-3 p-0">
                                                                            <p class="fw-semibold w-100 m-0" style="font-size: 12px;">
                                                                                Outcomes
                                                                            </p>
                                                                        </div>
                                                                        <div class="col-9 p-0 viewMoreText">
                                                                            Course ends with progression test
                                                                        </div>
                                                                    </div>
                                                                    <div class="row m-0 mt-3">
                                                                        <div class="col-3 p-0">
                                                                            <p class="fw-semibold w-100 m-0" style="font-size: 12px;">
                                                                                Suggested for
                                                                            </p>
                                                                        </div>
                                                                        <div class="col-9 p-0 viewMoreText">
                                                                            <!-- A Daily Session on Global Citizenship where students will have a great -->
                                                                        </div>
                                                                    </div>
                                                                    <div class="row m-0 mt-3">
                                                                        <p class="fw-semibold w-100 m-0" style="font-size: 12px;">
                                                                            Teachers
                                                                        </p>
                                                                        <div class="col-12 p-0 d-flex">
                                                                            <div class="teachers">
                                                                                <img src="../../../assets/images/default_user.png">
                                                                            </div>
                                                                            <div class="teachers">
                                                                                <img src="../../../assets/images/default_user.png">
                                                                            </div>
                                                                            <div class="teachers">
                                                                                <img src="../../../assets/images/default_user.png">
                                                                            </div>
                                                                            <div class="teachers">
                                                                                <img src="../../../assets/images/default_user.png">
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- <div *ngIf="currentuser=='student'" class="row m-0 mobile-only">
                                                                <p class="m-0 py-2">
                                                                    Grade {{grades[0]}} &nbsp;&nbsp;&nbsp; {{selectedBoard.board_name}}
                                                                </p>
                                                            </div>
                                                            <div *ngIf="currentuser=='teacher'" class="row m-0 ">
                                                                <p class="w-100 text-center fw-semibold">
                                                                    {{user_type?.value|titlecase}} info
                                                                </p>
                                                            </div>
                                                            <div class="row m-0">
                                                                <div *ngIf="currentuser=='student'" class="col-sm-6 col-12 p-0">
                                                                    <div class="row m-0 desktop-only">
                                                                        <p class="m-0 py-2">Grade {{grades[0]}} &nbsp;&nbsp;&nbsp; {{selectedBoard.board_name}}

                                                                        </p>
                                                                    </div>
                                                                    <p class="font-small m-0 pb-1">
                                                                        Curriculum
                                                                    </p>
                                                                    <div class="viewMoreText">
                                                                        <p class="m-0 py-1">
                                                                            Welcome to the worlds only Global Virtual School Secure a brighter future with an internationally-accredited online school education
                                                                        </p>
                                                                        <p class="m-0 py-1">
                                                                            &#9733; Live & Recorded Lessons : Access detailed course outlines, multi-sensory videos and interactive lessons available 24/7 on mobile, laptop, tablet and PC
                                                                        </p>
                                                                        <p class="m-0 py-1">
                                                                            &#9733; Easily track improvement across each of your courses and get marked assignments with teacher feedback
                                                                        </p>

                                                                        <p class="m-0 py-1">
                                                                            &#9733; Global Virtual School offers the core subjects of English, Mathematics and Science aligned to the British curriculum along with Social Studies & Global Citizenship
                                                                        </p>
                                                                        <p class="m-0 py-1">
                                                                            &#9733; Primary courses end with a progression test which is written at any time of the year, when the student is ready to write. A successful pass will enable the student to progress to the next stage/grade
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                                <div class="col-sm-6 col-12 p-0 dpl-4 mmt-4" *ngIf="currentuser == 'student'">
                                                                    <div class="row m-0 mb-3 d-none d-sm-flex" style="border: 1px solid #F2DFFF; border-radius: 13px; box-shadow: inset 0px 0px 7px rgb(161 105 192 / 74%);">
                                                                        <div class="col-3" style="padding: 8px;">
                                                                            <div class="w-100">
                                                                                <p class="m-0">
                                                                                    <img style="border-radius: 7px; aspect-ratio: 1;" src="../../../assets/images/principal-img.png" width="100%">
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-9 font-10" style="padding: 5px;">
                                                                            <p class="m-0 fw-semibold">
                                                                                {{selectedPrincipal?.name}}
                                                                            </p>
                                                                            <p class="m-0 fw-regular">Principal</p>
                                                                            <p class="m-0 fw-regular">
                                                                                {{selectedPrincipal?.email}}</p>
                                                                            <p class="m-0 fw-light"><img src="../../../assets/images/Icon metro-location.svg" width="8px" class="mr-2">{{selectedCity?.city}}, {{selectedCity?.country}}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row m-0">
                                                                        <div class="col-12 p-0">
                                                                            <div class="row m-0">
                                                                                <p class="m-0 p-0 font-xsmall fw-semibold">
                                                                                    Required</p>
                                                                            </div>
                                                                            <div class="row m-0 my-1 grade-details">
                                                                                <p>Students must know how to read</p>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                        <div class="col-12 p-0">
                                                                            <div class="row m-0">
                                                                                <p class="m-0 p-0 font-xsmall text-left fw-semibold">
                                                                                    Outcomes</p>
                                                                            </div>
                                                                            <div class="row m-0 my-1 grade-details">
                                                                                <p>Course ends with progression test</p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-12 p-0">
                                                                            <div class="row m-0">
                                                                                <p class="m-0 p-0 font-xsmall text-left fw-semibold">
                                                                                    Suggested for
                                                                                </p>
                                                                            </div>
                                                                            <div class="row m-0 my-1 grade-details">
                                                                                <p>{{selectedSuggestion[0]?.suggestion}}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-12 p-0">
                                                                            <div class="row m-0">
                                                                                <p class="m-0 p-0 font-xsmall text-left">
                                                                                    Fees <span class="ml-2" style="color: #747474;">$2000/-</span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-7 p-0">
                                                                            <div class="row m-0">
                                                                                <p class="font-xsmall m-0 p-0">Teachers
                                                                                </p>
                                                                            </div>
                                                                            <div class="row m-0">
                                                                                <div class="teachers">
                                                                                    <img src="../../../assets/images/default_user.png">
                                                                                </div>
                                                                                <div class="teachers">
                                                                                    <img src="../../../assets/images/default_user.png">
                                                                                </div>
                                                                                <div class="teachers">
                                                                                    <img src="../../../assets/images/default_user.png">
                                                                                </div>
                                                                                <div class="teachers">
                                                                                    <img src="../../../assets/images/default_user.png">
                                                                                </div>
                                                                                <button class="btn p-0 teachers" style="border-radius: 50%;">
                                                                                    <img
                                                                                        src="../../../assets/images/default_user.png">
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div *ngIf="currentuser == 'teacher'" class="row m-0">
                                                                        <div class="col-12 p-0">
                                                                            <div class="row m-0">
                                                                                <p class="m-0 p-0 font-xsmall text-left fw-semibold">
                                                                                    Outcomes
                                                                                </p>
                                                                            </div>
                                                                            <div class="row m-0 my-1 grade-details">
                                                                                <p>
                                                                                    Best Opportunity
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div *ngIf="currentuser == 'teacher'" class="row m-0">
                                                                        <div class="col-12 p-0">
                                                                            <div class="row m-0">
                                                                                <p class="m-0 p-0 font-xsmall text-left fw-semibold">
                                                                                    Certificate</p>
                                                                            </div>
                                                                            <div class="row m-0 my-1 grade-details">
                                                                                <p>
                                                                                    Experience certificate
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div *ngIf="currentuser == 'teacher'" class="row m-0">
                                                                        <div class="col-12 p-0">
                                                                            <div class="row m-0">
                                                                                <p class="m-0 p-0 font-xsmall text-left fw-semibold">
                                                                                    Experience
                                                                                </p>
                                                                            </div>
                                                                            <div class="row m-0 my-1 grade-details">
                                                                                <p>
                                                                                    Online teaching experience
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div *ngIf="currentuser == 'teacher'" class="row m-0">
                                                                        <div class="col-12 p-0">
                                                                            <div class="row m-0">
                                                                                <p class="m-0 p-0 font-xsmall text-left fw-semibold">
                                                                                    Payscale
                                                                                </p>
                                                                            </div>
                                                                            <div class="row m-0 my-1 grade-details">
                                                                                <p>
                                                                                    Starting from 10,000INR
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div *ngIf="currentuser == 'teacher'" class="row m-0">
                                                                        <div class="col-12 p-0">
                                                                            <div class="row m-0">
                                                                                <p class="m-0 p-0 font-xsmall text-left fw-semibold">
                                                                                    Payment mode
                                                                                </p>
                                                                            </div>
                                                                            <div class="row m-0 my-1 grade-details">
                                                                                <p>Credit Card, Debit Card, UPI, Internet Banking Mobile Banking</p>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div *ngIf="currentuser=='teacher' && user_type?.value=='teacher'" class="col-sm-6 col-12 p-0">
                                                                    <p class="font-small m-0 pb-1">
                                                                        Job brief
                                                                    </p>
                                                                    <div class="viewMoreText">
                                                                        <p class="m-0 py-1">
                                                                            &#9733; We’re looking for a competent Teacher Assistant to help our lead Teachers in educating our students. You’ll get to work with children of various ages and educational needs, while learning how to craft effective lesson plans and organize classrooms.
                                                                        </p>
                                                                        <p class="m-0 py-1">
                                                                            
                                                                            &#9733; As a Teacher Assistant, you’ll be responsible for individual students – or smaller groups of students – who need assistance to absorb the lesson material. You’ll work with the lead Teacher to build plans for class and you’ll also help them with
                                                                            record-keeping.
                                                                        </p>
                                                                        <p class="m-0 py-1">
                                                                            &#9733; To do this job well, you should be smart and patient with a positive attitude. If you’re ready to develop yourself in the world of teaching and do some real good for our students, we’d like to meet you.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6 col-12 p-0 dpl-4 viewMoreText mmt-4" *ngIf="currentuser == 'teacher' && user_type?.value=='teacher'">
                                                                    <p class="font-small m-0 pb-1">
                                                                        Responsibilities
                                                                    </p>
                                                                    <p class="m-0 py-1">
                                                                        &#9733; Help teachers prepare lesson plans
                                                                    </p>
                                                                    <p class="m-0 py-1">
                                                                        &#9733; Set up necessary material for classes (e.g. projectors, chemistry sets)

                                                                    </p>
                                                                    <p class="m-0 py-1">
                                                                        &#9733; Track student attendance and class schedules

                                                                    </p>
                                                                    <p class="m-0 py-1">
                                                                        &#9733; Review material taught in class with individual students with learning challenges

                                                                    </p>
                                                                    <p class="m-0 py-1">
                                                                        &#9733; Work with smaller groups of students for remedial teaching or reinforcing the learning process

                                                                    </p>
                                                                    <p class="m-0 py-1">
                                                                        &#9733; Escort and supervise students in field trips and school activities

                                                                    </p>
                                                                    <p class="m-0 py-1">
                                                                        &#9733; Make sure classrooms are safe and tidy before classes start

                                                                    </p>
                                                                    <p class="m-0 py-1">
                                                                        &#9733; Assist teachers with various tasks, like grading assignments and informing parents of their children’s progress

                                                                    </p>
                                                                    <p class="m-0 py-1">
                                                                        &#9733; Help students adjust, learn and socialize and report to teachers about possible behavioral issues
                                                                    </p>
                                                                </div>
                                                                <div *ngIf="currentuser=='teacher' && user_type?.value=='principal'" class="col-sm-6 col-12 p-0">
                                                                    <p class="font-small m-0 pb-1">
                                                                        Task brief
                                                                    </p>
                                                                    <div class="viewMoreText">
                                                                        <p class="m-0 py-1">
                                                                            &#9733;
                                                                            We are looking for a capable Leader to partner with us with a minimum 1 yr overall experience and foster a healthy learning environment for the students & teachers in their city.
                                                                        </p>
                                                                        <p class="m-0 py-1">
                                                                            &#9733;
                                                                            Your primary responsibilities will be to supervise your education and oversee day-to-day online school operations.
                                                                        </p>
                                                                        <p class="m-0 py-1">
                                                                            &#9733;
                                                                            To succeed in this role, you should be able to make quick decisions under pressure and have a comprehensive understanding of the national and International education system and its regulations. Excellent communication and organizational abilities are
                                                                            also important skills you should demonstrate as a principal.
                                                                        </p>
                                                                        <p class="m-0 py-1">
                                                                            &#9733; If you meet these requirements and you’re passionate about a leadership role in education, we’d like to meet you.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6 col-12 p-0 dpl-4 viewMoreText mmt-4" *ngIf="currentuser == 'teacher' && user_type?.value=='principal'">
                                                                    <p class="font-small m-0 pb-1">
                                                                        Responsibilities
                                                                    </p>
                                                                    <p class="m-0 py-1">
                                                                        &#9733; Oversee day-to-day online school operations
                                                                    </p>
                                                                    <p class="m-0 py-1">
                                                                        &#9733; Manage school logistics and budgets
                                                                    </p>
                                                                    <p class="m-0 py-1">
                                                                        &#9733;
                                                                        Set learning goals for students and teachers based on national and international curricula.

                                                                    </p>
                                                                    <p class="m-0 py-1">
                                                                        &#9733;
                                                                        Monitor and report on teacher performance Reviews and timely Appraisals
                                                                    </p>
                                                                    <p class="m-0 py-1">
                                                                        &#9733;
                                                                        Present data from school performance to board members regularly

                                                                    </p>
                                                                    <p class="m-0 py-1">
                                                                        &#9733;
                                                                        Research new resources and techniques to improve teaching pedagogy
                                                                    </p>
                                                                    <p class="m-0 py-1">
                                                                        &#9733;
                                                                        Interview and hire student counts meeting Targets regularly
                                                                    </p>
                                                                    <p class="m-0 py-1">
                                                                        &#9733;
                                                                        Review and implement school policies
                                                                    </p>
                                                                    <p class="m-0 py-1">
                                                                        &#9733;
                                                                        Provide guidance and counseling to teachers, if required,.. Else forward details to the Management
                                                                    </p>
                                                                    <p class="m-0 py-1">
                                                                        &#9733;
                                                                        Handle emergencies and school crises from time to time
                                                                    </p>
                                                                    <p class="m-0 py-1">
                                                                        &#9733;
                                                                        Organize school online events and assemblies
                                                                    </p>
                                                                    <p class="m-0 py-1">
                                                                        &#9733;
                                                                        Attend conferences to gain knowledge on current educational trends
                                                                    </p>
                                                                    <p class="m-0 py-1">
                                                                        &#9733;
                                                                        Partnership investment will be $1000 complete information for the same will be provided in the interview for which you will be invited once you apply..
                                                                    </p>
                                                                </div>
                                                            </div> -->
                                                        </div>
                                                    </div>
                                                    <div class="row m-0 mx-5">
                                                        <div class=" col-7 offset-5 p-0">
                                                            <button class="btn btn-proceed" (click)="currentuser=='student'?(studentTab=5 && studentTabDone=5):(teacherTab=4 && teacherTabDone=4)">Proceed</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="studentTab==5 || teacherTab==7" class="row m-0">
                                        <div class="col-12  offset-sm-1 col-sm-10 p-0">
                                            <div class="row m-0 mx-4 mx-md-5 px-md-5 mt-2 p-4 justify-content-center mobile-s-tab4">
                                                <p class="w-100 m-0 pb-4 text-center">A little about yourself</p>
                                                <div *ngIf="currentuser=='teacher'" class="col-6 p-0">
                                                    <img *ngIf="!isImage" [src]="profileImg" onerror="src='./assets/images/default_user.png'" id="profily" class="w-100 rounded-circle">

                                                    <image-cropper *ngIf="isImage" class="w-100" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="1 / 1" format="png" (imageCropped)="imageCropped($event)">
                                                    </image-cropper>
                                                    <label *ngIf="isImage" class="btn btn-md btn-red d-flex justify-content-center mt-2" (click)="submitProfile()">
                                                        Done
                                                    </label>
                                                    <label *ngIf="!isImage" for="file-upload" class="btn btn-md btn-red d-flex justify-content-center mt-2">
                                                        Upload Picture
                                                    </label>
                                                    <input class="hide" id="file-upload" type="file" accept="image/*" (change)="fileChangeEvent($event)" />
                                                </div>
                                                <input formControlName="name" type="text" placeholder="Name">
                                                <div class="errorBox" *ngIf="name?.invalid && (name?.dirty || name?.touched)">
                                                    <div *ngIf="name?.errors?.required">Name required.</div>
                                                    <div *ngIf="name?.errors?.pattern || name?.errors?.maxlength">
                                                        Invalid Name.</div>
                                                    <div *ngIf="name?.errors?.minlength">Minimum 6 character.</div>
                                                </div>
                                                <input formControlName="mobile" class="mt-4" type="text" placeholder="Phone Number" (keyup)="checkMobile()">
                                                <div class="errorBox" *ngIf="mobile?.invalid && (mobile?.dirty || mobile?.touched)">
                                                    <div *ngIf="mobile?.errors?.required">Mobile required.</div>
                                                    <div *ngIf="mobile?.errors?.pattern">Invalid mobile.</div>
                                                    <div *ngIf="mobile?.errors?.mobile_invalid">Invalid mobile.</div>
                                                </div>

                                                <input formControlName="email" class="mt-4" type="email" placeholder="Email ID">
                                                <div class="errorBox" *ngIf="email?.invalid && (email?.dirty || email?.touched)">
                                                    <div *ngIf="email?.errors?.required">Email required.</div>
                                                    <div *ngIf="email?.errors?.pattern || email?.errors?.maxlength">
                                                        Invalid email.</div>
                                                </div>

                                                <input *ngIf="currentuser == 'student'" formControlName="parent_name" class="mt-4" type="text" placeholder="Parent/ Gaurdian's name">
                                                <div class="errorBox" *ngIf="parent_name?.invalid && (parent_name?.dirty || parent_name?.touched)">
                                                    <div *ngIf="parent_name?.errors?.required">Parent Name required.
                                                    </div>
                                                    <div *ngIf="parent_name?.errors?.pattern || parent_name?.errors?.maxlength">
                                                        Invalid Parent Name.</div>
                                                    <div *ngIf="parent_name?.errors?.minlength">Minimum 6 character.
                                                    </div>
                                                </div>

                                                <input *ngIf="currentuser == 'teacher'" formControlName="postal_address" class="mt-4" type="text" placeholder="Postal Address">
                                                <div class="errorBox" *ngIf="postal_address?.invalid && (postal_address?.dirty || postal_address?.touched)">
                                                    <div *ngIf="postal_address?.errors?.required">Address required.
                                                    </div>
                                                    <div *ngIf="postal_address?.errors?.pattern || postal_address?.errors?.maxlength">
                                                        Invalid Address.</div>
                                                    <div *ngIf="postal_address?.errors?.minlength">Minimum 10 character.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="studentTab==6 || teacherTab==8" class="row mx-md-5 mx-3">
                                        <div class="col-12  p-0">
                                            <p class="m-0 fw-semibold">Reserve your seat at GVS to secure your quality education for only <span style="color: #A96DD3;">$10</span></p>
                                            <p class="m-0 pt-2" style="font-size: 15px;">The reservation fee ensures that you do not lose your seat and rest assured about seats filling out! We only accept applications after interviewing each student personally to ensure that your children only learn
                                                with the best, from the best!</p>
                                            <p class="m-0 pt-4 text-center">Application fee &nbsp;&nbsp;&nbsp; Total : $10
                                            </p>
                                        </div>
                                    </div>
                                    <div *ngIf="studentTab==7 || teacherTab==9" class="row m-0 my-4">
                                        <div class="col-12 p-0">
                                            <div class="row m-0 justify-content-center">
                                                <p class="m-0 pt-3 text-center">Why GVS is the best option for you</p>
                                            </div>
                                            <div class="row m-4 position-relative">
                                                <img src="../../../assets/images/take-tour.svg" class="w-100" style="border: 1px solid #707070; border-radius: 15px;">
                                                <img src="../../../assets/images/Icon ionic-md-play-circle.svg" style="position: absolute; top: 38%; left: 42%;" width="50px">
                                            </div>
                                            <div class="row m-0">
                                                <div class="col-12 col-sm-4 p-0 mobile-tab6-desc">
                                                    <div class="w-100 d-flex flex-column align-items-center">
                                                        <div class="mobile-s-tab6">
                                                            <img src="../../../assets/images/Icon awesome-globe-americas.svg" width="27px">
                                                        </div>
                                                    </div>
                                                    <p class="m-0 pt-2 text-center">150+ Countries</p>
                                                </div>
                                                <div class="col-12 col-sm-4 p-0 mobile-tab6-desc">
                                                    <div class="w-100 d-flex flex-column align-items-center">
                                                        <div class="mobile-s-tab6">
                                                            <img src="../../../assets/images/Icon material-location-city.svg" width="27px">
                                                        </div>
                                                    </div>
                                                    <p class="m-0 pt-2 text-center">25000+ Cities</p>
                                                </div>
                                                <div class="col-12 col-sm-4 p-0 mobile-tab6-desc">
                                                    <div class="w-100 d-flex flex-column align-items-center">
                                                        <div class="mobile-s-tab6">
                                                            <img src="../../../assets/images/Icon awesome-user-graduate.svg" width="27px">
                                                        </div>
                                                    </div>
                                                    <p class="m-0 pt-2 text-center"> IGSCE- Cambridge</p>
                                                </div>
                                            </div>
                                            <div class="row m-0 mx-4 justify-content-center">
                                                <p class="m-0 pt-4 fw-light text-left" style="font-size: 14px;">Only 10 students from a city in each grade to provide an unmatched quality of education to each individual
                                                </p>
                                                <p class="m-0 mt-4 text-center cursor-pointer about" (click)="studentTabDone > 1? studentTab=5:studentTab=0">
                                                    Back
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="studentTab==8 || teacherTab==10" class="row m-0 my-4 mx-md-5 mx-4">
                                        <div class="col-12 p-0">
                                            <div class="row m-0">
                                                <img src="../../../assets/images/grafetti.svg" alt="" width="100%">
                                            </div>
                                            <div class="row m-0 p-4" style="border-radius: 13px; box-shadow: -3px 11px 29px rgb(170 113 198 / 20%); margin-top: -15px !important;">
                                                <p class="m-0 py-2">Your application has been submitted successfully</p>
                                                <img src="../../../assets/images/take-tour.svg" class="w-100">
                                            </div>
                                            <div class="row m-0 justify-content-center">
                                                <p class="m-0 mt-5">Check out what GVS is all about !</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row m-0">
                                        <div class="w-100 pt-4 text-center" *ngIf="errorMsg && errorMsg.length>0" style="color: rgb(189, 13, 13);font-family: arial;">
                                            <p>{{errorMsg}}</p>
                                        </div>

                                        <div *ngIf="studentTab==1" class="w-100">
                                            <div class="row m-0 my-3">
                                                <button *ngIf="studentTab==1" [disabled]="!selectedCity.id || selectedCity.id<=0" class="btn btn-next" (click)="studentTab!=2?(studentTab=2):'';studentTabDone!=studentTab?studentTabDone=studentTab:''">Next</button>
                                            </div>
                                        </div>
                                        <button *ngIf="studentTab==2" [disabled]="!grades || grades.length<=0" class="btn btn-next" (click)="studentTab!=3?(studentTab=3):'';studentTabDone!=studentTab?studentTabDone=studentTab:'';setSlider()">Proceed
                                            to Application</button>
                                        <div *ngIf="studentTab==5" class="col-12 offset-sm-2 col-sm-8">
                                            <button class="btn getInfo" (click)="studentTab!=7 ? (studentTab=7) : ''">Get Information</button>
                                            <button type="submit" [disabled]="name?.errors || mobile?.errors || email?.errors || parent_name?.errors" class="btn text-white applyNow">Apply Now</button>
                                        </div>
                                        <a *ngIf="studentTab==6" class="btn btn-next text-white" (click)="razorOrder(currUserId)">Proceed to payment
                                        </a>

                                        <button *ngIf="teacherTab==1" [disabled]="!selectedCity.id || selectedCity.id<=0" class="btn btn-next" (click)="teacherTab!=2?(teacherTab=2):'';teacherTabDone!=teacherTab?teacherTabDone=teacherTab:''">Next</button>
                                        <button *ngIf="teacherTab==2" [disabled]="user_type?.errors || desc?.errors" class="btn btn-next" (click)="teacherTab!=3?(teacherTab=3):'';teacherTabDone!=teacherTab?teacherTabDone=teacherTab:'';setSlider()">Next</button>
                                        <button *ngIf="teacherTab==4" [disabled]="subject?.errors" class="btn btn-next" (click)="teacherTab!=5?(teacherTab=5):'';teacherTabDone!=teacherTab?teacherTabDone=teacherTab:''">Next</button>
                                        <button *ngIf="teacherTab==5" [disabled]="!grades || grades.length<=0" class="btn btn-next" (click)="teacherTab!=6?(teacherTab=6):'';teacherTabDone!=teacherTab?teacherTabDone=teacherTab:''">Next</button>
                                        <button *ngIf="teacherTab==6" [disabled]="experience?.errors || rname.length<=0" class="btn btn-next" (click)="teacherTab!=7?(teacherTab=7):'';teacherTabDone!=teacherTab?teacherTabDone=teacherTab:''">Next</button>
                                        <button type="submit" *ngIf="teacherTab==7" [disabled]="name?.errors || mobile?.errors || email?.errors || postal_address?.errors" class="btn btn-next">Submit Application</button>
                                        <a *ngIf="teacherTab==8" class="btn btn-next text-white" (click)="razorOrder(currUserId)">Proceed to payment
                                        </a>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row m-0 desktop-only desktopAbout d-none">
        <a class="text-white float-right fw-light" style="font-size: 22px;">About us</a>
    </div>
</div>