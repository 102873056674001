import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { consts } from 'src/app/config';
import { ApiService } from 'src/app/service/api.service';
import { WindowrefService } from 'src/app/service/windowref.service';

const PNF = require('google-libphonenumber').PhoneNumberFormat;
const PNV = require('google-libphonenumber').PhoneNumberUtil.ValidationResult;
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {

  public schools = [
    {
      id: '1',
      name: "Global Virtual School",
      slug: "gvs",
      title: "Quality Education at Your Doorstep",
      std: "Cambridge Board British Curriculum",
      board: "IGCSE"
    }
    // ,
    // {
    //   id: '2',
    //   name: "The Cathedral & John Connon",
    //   slug: "cathedral",
    //   title: "Online Global School",
    //   std: "KG to 12th",
    //   board: "IGCSE"
    // },
    // {
    //   id: '3',
    //   name: "Vivek Bindras",
    //   slug: "badabusiness",
    //   title: "Global School for entrepreneurs",
    //   std: "Kg to Masters",
    //   board: ""
    // },
    // {
    //   id: '4',
    //   name: "Radha Kishan Damanis",
    //   slug: "radhakishandamani",
    //   title: "Global School for entrepreneurs",
    //   std: "Kg to Masters",
    //   board: ""
    // }
  ]

  public subjects: any = [];
  // [{ id: "1", subject: 'Maths' },
  // { id: "2", subject: 'Science' },
  // { id: "3", subject: 'English' },
  // { id: "4", subject: 'Account' },
  // { id: "5", subject: 'Biology' },
  // ]
  public country_code: any = '';
  public selectedSchool: any = [];
  public slug: any = '';
  public iname: any = '';
  public imagePath: any;
  public isImage: boolean = false;
  public fileToReturn: any;
  public imageChangedEvent: any = '';
  public croppedImage: any = '';
  public imageName: any;
  public profileImg: any = "";
  public currUserId: any = "";
  public searchText: any = "";
  public showCity: any = false;
  public errorMsg: any = "";
  public cityErrorMsg: any = "";
  public resumeForm: FormData = new FormData();
  public imageForm: FormData = new FormData();
  public selectedSuggestion: any = '';
  public rname: any = '';
  public resumePath: any;
  public resumeURL: any;
  public resumeFile: any;
  public suggestions: any = [
    { std: 1, suggestion: '6-7 Year old children' },
    { std: 2, suggestion: '7-8 Year old children' },
    { std: 3, suggestion: '8-9 Year old children' },
    { std: 4, suggestion: '9-10 Year old children' },
    { std: 5, suggestion: '10-11 Year old children' },
    { std: 6, suggestion: '11-12 Year old children' },
    { std: 7, suggestion: '13-14 Year old children' },
  ]
  public selectedCity: any = [];
  public selectedPrincipal: any = [];
  public selectedBoard: any = [];
  public boards: any = [];
  public principals: any = [];
  public oldCities: any = [];
  public cities: any = [];
  public topCities: any = [];
  public grades: any = [];
  public currentuser: any;
  public studentTab: any;
  public studentTabDone: any = 1;
  public teacherTabDone: any = 1;
  public teacherTab: any;
  public kindergartenStd: any = ['Nursery', 'LKG', 'UKG']
  public juniorStd: any = ['1', '2', '3', '4']
  public middleStd: any = ['5', '6', '7', '8', '9']
  public highStd: any = ['10', '11', '12']


  public razorPayOptions: any = {
    key: null, // Enter the Key ID generated from the Dashboard
    amount: null, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    currency: "USD",
    name: "",
    description: "Test Transaction",
    image: "../../../assets/images/default_user.png",
    order_id: null, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    callback_url: consts.api + "process-razorpay-payment-response",
    prefill: {
      name: "",
      email: "",
      contact: "",
    },
    notes: {
      address: "Razorpay Corporate Office",
    },
    theme: {
      color: "#F37254",
    },
  };

  descSlider: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    navSpeed: 700,
    dots: true,
    margin: 0,
    responsive: {
      0: {
        items: 1,
      },
      200: {
        items: 1,
      },
      560: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
      },
    },
    nav: false,
    // navText: [, "<div class='next-btn'><img src='../../../assets/images-added/Group 3934.svg'></div>"],
  }

  constructor(
    private router: Router,
    private http: HttpClient,
    private windowService: WindowrefService,
    private api: ApiService,
  ) {
    if (window.location.pathname === '/' || window.location.pathname === '/student' || window.location.pathname === '/teacher') {
      this.selectedSchool = this.schools[0];
      this.slug = this.selectedSchool.slug;
    } else {
      this.schools.forEach(school => {
        if (window.location.pathname.substring(1).toLowerCase().includes(school.slug.toLowerCase())) {
          this.slug = school.slug;
          this.selectedSchool = school;
        }
        else {
          this.router.navigate(['/gvs'])
          this.slug = this.schools[0].slug;
          this.selectedSchool = this.schools[0];
        }
      });
    }
    if (window.location.href.includes('status=success')) {
      if (window.location.href.includes('teacher')) {
        this.currentuser = 'teacher';
        this.teacherTab = this.teacherTabDone = 10;
      } else {
        this.currentuser = 'student';
        this.studentTab = this.studentTabDone = 8;
      }
    } else {
      if (window.location.href.includes('teacher')) {
        this.currentuser = 'teacher';
        this.teacherTab = 1;
      } else if (window.location.href.includes('student')) {
        this.currentuser = 'student';
        this.studentTab = 1;
      } else {
        this.currentuser = 'student';
        this.studentTab = this.studentTabDone = 0;
      }
    }
    
    this.topCities = localStorage.getItem('topCities')
    if(this.topCities && this.topCities.length>0){
      this.topCities=JSON.parse(this.topCities)
    }
    
  }
  private emailRegex = /^[^<>()[\]\\,;:\%#^\s@\"$&!@]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}))$/;
  registrationForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z ]+$/u), Validators.minLength(6), Validators.maxLength(50)]),
    email: new FormControl('', [Validators.required, Validators.maxLength(255), Validators.pattern(this.emailRegex)]),
    mobile: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$")]),
    city: new FormControl('', [Validators.required]),
    grade: new FormControl('', [Validators.required]),
    age: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(1), Validators.maxLength(2)]),
    user_type: new FormControl('principal', [Validators.required]),
    desc: new FormControl('', [Validators.required, Validators.minLength(100)]),
    experience: new FormControl('', [Validators.required]),
    experience_in_year: new FormControl('', [Validators.required]),
    subject: new FormControl('', [Validators.required]),
    is_teacher: new FormControl('', [Validators.required]),
    parent_name: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z ]+$/u), Validators.minLength(6), Validators.maxLength(50)]),
    postal_address: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(100)]),
  });
  get name() { return this.registrationForm.get('name') }
  get mobile() { return this.registrationForm.get('mobile') }
  get email() { return this.registrationForm.get('email') }
  get age() { return this.registrationForm.get('age') }
  get user_type() { return this.registrationForm.get('user_type') }
  get desc() { return this.registrationForm.get('desc') }
  get experience() { return this.registrationForm.get('experience') }
  get experience_in_year() { return this.registrationForm.get('experience_in_year') }
  get parent_name() { return this.registrationForm.get('parent_name') }
  get postal_address() { return this.registrationForm.get('postal_address') }
  get subject() { return this.registrationForm.get('subject') }

  ngOnInit(): void {
    this.getGradeCity()
  }
  getGradeCity() {
    this.api.get(`get-grade-and-city`)
      .toPromise()
      .then((res: any) => {

        if (res.status == 'success' || res.status == 'Success' || res.status == 'SUCCESS') {
          this.boards = res.board;
          this.selectedBoard = this.boards[0];
          this.principals = res?.principals;

          this.subjects = res?.subjects;
          this.cities = res.city;
          this.oldCities = res.city;
          this.topCities = res.top_city;
          localStorage.setItem("topCities",JSON.stringify(this.topCities))
        }
      })
      .catch(e => console.log(e));
  }
  setGrade(std: any) {
    let stdBox = document.querySelectorAll('.box');
    if (this.currentuser == 'student') {
      this.grades = [];
      stdBox.forEach(b => {
        if (b.classList.contains('active')) {
          b.classList.remove('active')
        }
        if ((b.children[0].innerHTML).toLowerCase() == std.toLowerCase()) {
          b.classList.add('active')
        }
      });
      this.grades.push(std);
    } else {
      stdBox.forEach(b => {
        if ((b.children[0].innerHTML).toLowerCase() == std.toLowerCase()) {
          if (b.classList.contains('active')) {
            b.classList.remove('active')
            let s = this.grades.filter(function (ele: any) {
              return (ele != std)
            });
            this.grades = s;
          } else {
            this.grades.push(std);
            b.classList.add('active')
          }
        }
      });
    }

    this.registrationForm.patchValue({ grade: this.grades.sort() })
  }

  setUserType(user: any) {
    let utype = document.querySelectorAll('.uType');
    utype.forEach(ut => {
      if (ut.classList.contains('active')) {
        ut.classList.remove('active')
      }
      if (ut.innerHTML.toLowerCase() == user.toLowerCase()) {
        ut.classList.add('active')
      }
    });
    this.registrationForm.patchValue({
      user_type: user,
    });
  }
  setExperience(experience: any) {
    let uExperience = document.querySelectorAll('.experience');
    uExperience.forEach(ut => {
      if (ut.classList.contains('active')) {
        ut.classList.remove('active')
      }
      if (ut.innerHTML.toLowerCase() == experience.toLowerCase()) {
        ut.classList.add('active')
      }
    });
    this.registrationForm.patchValue({
      experience: experience,
    });
  }
  setexperience_in_year(experience_in_year: any) {
    let uexperience_in_year = document.querySelectorAll('.experience_in_year');
    uexperience_in_year.forEach(ul => {
      if (ul.classList.contains('active')) {
        ul.classList.remove('active')
      }
      if (ul.innerHTML.toLowerCase() == experience_in_year.toLowerCase()) {
        ul.classList.add('active')
      }
    });
    this.registrationForm.patchValue({
      experience_in_year: experience_in_year,
    });
  }

  onSubmit() {
    if (this.resumePath && this.rname) {
      this.resumeForm.append('resume', this.resumePath, this.rname);
    }

    this.registrationForm.patchValue({
      is_teacher: this.currentuser == 'student' ? 0 : 1,
      city: JSON.stringify(this.selectedCity.id),
      grade: JSON.stringify(this.grades),
      subject: JSON.stringify(this.subject?.value),
    });
    for (const field in this.registrationForm.controls) {
      this.resumeForm.append(field, this.registrationForm?.get(field)?.value)
    }


    this.http.post<any>(consts.api + 'register', this.resumeForm).subscribe(
      (res: any) => {
        if (res && (res.status == 'success' || res.status == 'Success' || res.status == 'SUCCESS')) {
          this.currUserId = res?.user?.id;
          if (this.currentuser == 'student') {
            this.studentTab = this.studentTabDone = 6;
          } else {
            this.teacherTab = this.teacherTabDone = 8;
          }
        } else if (res && (res.status == 'fail' || res.status == 'Fail' || res.status == 'FAIL')) {

          this.errorMsg = res.messages.email[0];
          setTimeout(() => {
            this.errorMsg = '';
            if (res?.user && res?.user?.id && res?.user?.payment_status != "completed-success") {
              this.currUserId = res?.user?.id;
              if (this.currentuser == 'student') {
                this.studentTab = this.studentTabDone = 6;
              } else {
                this.teacherTab = this.teacherTabDone = 8;
              }
            }
          }, 5000);
        } else {
          console.log(res);
        }
      },
      (err: any) => console.log("err >>> ", err)
    )
  }


  razorOrder(uid: any) {
    this.api.post('order', {
      user_id: uid
    })
      .toPromise()
      .then((res: any) => {
        console.log(res.order_id);
        if (res.status == 'success' || res.status == 'Success' || res.status == 'SUCCESS') {
          let razorResp = res
          console.log("order response:", razorResp.razor_order);
          this.razorPayOptions.name = this.name?.value;
          this.razorPayOptions.key = razorResp.razor_order.razorpay_key_id;
          this.razorPayOptions.description = "";
          this.razorPayOptions.amount = razorResp.razor_order.amount;
          this.razorPayOptions.order_id = razorResp.razor_order.id;
          this.razorPayOptions.prefill.name = this.name?.value;
          this.razorPayOptions.prefill.email = this.email?.value;
          this.razorPayOptions.prefill.contact = this.mobile?.value;
          this.razorPayOptions.callback_url = consts.api + "process-razorpay-payment-response?slug=" + this.slug,
            console.log("options", this.razorPayOptions);
          console.log(this.razorPayOptions);

          const rzp = new this.windowService.nativeWindow.Razorpay(
            this.razorPayOptions
          );
          rzp.open();
        }
      })
      .catch(e => console.log("e", e));
  }



  fileChange(e: any) {
    const image = e.target.files[0];

    var mimeType = image.type;
    var reader = new FileReader();

    this.resumePath = image;
    reader.readAsDataURL(image);

    reader.onload = (_event) => {
      this.resumeURL = reader.result;
      this.rname = image.name;
    }
  }

  imageChange(e: any) {
    const image = e.target.files[0];
    this.imagePath = image;
    this.iname = image.name;
    const formData: FormData = new FormData();
    let img = new Image();
    let reader = new FileReader();
    img.src = window.URL.createObjectURL(image);
    reader.readAsDataURL(image);
    reader.onload = () => {
    };
  }


  fileChangeEvent(event: any): void {
    this.imageName = event.target.files[0].name;
    this.imageChangedEvent = event;
    this.isImage = true;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.profileImg = event.base64;
    this.fileToReturn = this.base64ToFile(
      event.base64,
      this.imageName,
    )
    this.isImage = true;
  }

  base64ToFile(data: any, filename: any) {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  submitProfile() {
    this.resumeForm.append('image', this.fileToReturn, this.imageName);
    this.isImage = false;
  }
  selectedGrade(std: any) {
    let msg = false;

    if (this.grades.includes(std)) {
      msg = true;
    } else {
      msg = false;
    }



    return msg;
  }

  searchCity(e: any) {
    this.searchText = e?.target?.value;
    if (e?.target?.value.length > 0) {
      this.api.get(`search-city?city=${e?.target?.value}`)
        .toPromise()
        .then((res: any) => {
          if ((res.status == 'success' || res.status == 'Success' || res.status == 'SUCCESS') && res?.city.length > 0) {
            this.cities = res?.city;
            this.showCity = true;
            this.cityErrorMsg = "";
          } else {
            this.cities = res?.city;
            this.cityErrorMsg = "City not found";
          }
        })
        .catch(e => console.log(e));
    } else {
      this.showCity = false;
      this.cities = this.oldCities;
    }
  }
  selectCity(e: any) {

    this.selectedCity = this.cities.filter(function (ct: any) {
      return (ct.id == e)
    });
    if (this.selectedCity[0]) {
      this.selectedCity = this.selectedCity[0];
    } else {
      this.selectedCity = this.topCities.filter(function (ct: any) {
        return (ct.id == e)
      });
      this.selectedCity = this.selectedCity[0];
    }

    let sid = this.selectedCity.id;
    this.selectedPrincipal = this.principals.filter(function (p: any) {
      return p.city == sid;
    });
    this.selectedPrincipal = this.selectedPrincipal[0];
    this.country_code = this.selectedCity?.country_code;
    this.checkMobile();
  }
  openViewMore(std: any): void {
    this.selectedSuggestion = this.suggestions.filter(function (s: any) {
      return (s.std == std)
    });
    this.studentTab = 4;
    this.studentTabDone = 4;
  }
  leftSeat(s: any) {
    let msg = '';
    this.selectedCity.standard.forEach((std: any) => {
      if (std.standards[0]?.standard == s && std?.seat_left > 0) {
        msg = std?.seat_left + ' seat left';
      } else if (std?.seat_left == 0) {
        msg = 'Seat full';
      }
    });
    return msg;
  }

  comingSoon(e: any) {
    if (e.target.classList.contains('coming')) {
      e.target.parentElement.classList.remove('coming');
    } else {
      e.target.classList.add('coming');
      setTimeout(() => {
        e.target.classList.remove('coming');
      }, 3000);
    }
  }

  checkMobile() {
    let no;
    no = '+' + this.country_code + this.mobile?.value;

    if (no.length > 5) {
      const results = phoneUtil.parseAndKeepRawInput(no, '');

      if (phoneUtil.isValidNumber(results)) {
        // this.mobile.setErrors(null);
        console.log(phoneUtil.format(results, PNF.E164));
        console.log(phoneUtil.getRegionCodeForNumber(results));
      } else {
        this.mobile?.setErrors({
          mobile_invalid: "Invalid Number"
        });

        const code = phoneUtil.isPossibleNumberWithReason(results);
        switch (code) {
          case PNV.INVALID_COUNTRY_CODE:
            console.log('INVALID_COUNTRY_CODE');
            break;
          case PNV.TOO_SHORT:
            console.log('TOO_SHORT');
            break;
          case PNV.TOO_LONG:
            console.log('TOO_LONG');
            break;
          case PNV.IS_POSSIBLE_LOCAL_ONLY:
            console.log('IS_POSSIBLE_LOCAL_ONLY');
            break;
          case PNV.INVALID_LENGTH:
            console.log('INVALID_LENGTH');
            break;
          default:
            { console.log('something is wrong'); }
        }
      }
    } else {
      this.mobile?.setErrors({
        mobile_invalid: "Invalid Number"
      });
    }
  }

  setSlider() {
    setTimeout(() => {
      let slideBox = document.querySelectorAll(".slider-box")
      let maxHeight = 0;
      slideBox.forEach(e => {
        if (maxHeight < e.clientHeight) {
          maxHeight = e.clientHeight
        }
      });
      slideBox.forEach(e => {
        (e as HTMLElement).style.height = (maxHeight + 5) + "px";
      });

      let slideText = document.querySelectorAll(".slider-text")
      let maxH = 0;
      slideText.forEach(e => {
        if (maxH < e.clientHeight) {
          maxH = e.clientHeight
        }
      });
      
      slideText.forEach(e => {
        (e as HTMLElement).style.height = (maxH - 3) + "px";
      });
    }, 2500);
  }
  backtoHome() {
    this.router.navigate([this.slug])
    if (this.currentuser == 'student') {
      this.studentTab = 0;
    } else {
      this.teacherTab = 0;
    }
  }
}
