import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { consts } from '../config';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  baseUrl: string = consts.api;
  constructor(private client: HttpClient) { }
  public get(route: any, token = null, chat = false) {
    return this.client.get(`${this.baseUrl}${route}`)
  }

  public post(route: any, body: any, files = false, chat = false) {
    const options = {
      headers: {
        'Content-Type': files ? 'multipart/form-data' : 'application/json'
      }
    }
    return this.client.post(`${this.baseUrl}${route}`, body, options)
  }
}
