import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegistrationComponent } from './components/registration/registration.component';
// import { SignupComponent } from './components/signup/signup.component';

const routes: Routes = [
  { path: '', component: RegistrationComponent },
  { path: 'student', component: RegistrationComponent },
  { path: 'teacher', component: RegistrationComponent },

  { path: ':slug', component: RegistrationComponent },
  { path: ':slug/student', component: RegistrationComponent },
  { path: ':slug/teacher', component: RegistrationComponent },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule {

}
